$(function () {
  window.onscroll = function () {
    var check = window.pageYOffset;
    var docHeight = $(document).height();
    var dispHeight = $(window).height();
    var footerHight = $(".footer__wrapper").outerHeight(true);

    if(check > docHeight-dispHeight-footerHight){
        $('.guidance__button--wrapper').fadeOut(300);
    }else{
        $('.guidance__button--wrapper').fadeIn(300);
    }
  };
});